<template>
  <div class="solucion3">
    <div class="wrapper">
      <Section1 />
      <Section2 />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from "@/components/pay/solucion/page3/Section1";
import Section2 from "@/components/pay/solucion/page3/Section2";

export default {
  name: "solucion3",
  components: {
    Section1,
    Section2,
  },
  head: {
    title: {
      inner: "PAGOS POR CENTRALITA"
    },
  },
  mounted() {
    this.$i18n.locale = "es";
  }
};
</script>