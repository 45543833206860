<template>
  <div id="section2">
    <div class="fondoWhite" >
      <b-container fluid>
        <b-container>
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <p>
            {{$t("payText")}}
          </p>
          <br>
            <b-row align-h="around" >
              <b-col md="4" sm="12" class="text-center"
                v-for="(item, idx) in payList" :key="idx">
                <div class="text-center">
                  <b-img :src="payListI[idx].btnImg"/>
                  <br>
                  <h4>
                    {{item.txt}}
                  </h4>
                  <h3>
                    {{item.titulo}}
                  </h3>
                  <p>
                    {{item.texto}}
                  </p>
                </div>
              </b-col>
            </b-row>
          <br />
          <div class="text-center">
            <a @click="menuClicked(payUrl)" class="btn buttonTurqueza" >
              {{$t("payBtn")}}
            </a>
          </div>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Make all your payments by phone or invoice.",
    "payText": "From anywhere, instantly and via any device. This is how your customers can make payments by switchboard and by invoice.",    
    "payBtn": "CONTRATA AHORA",
    "payList": [
      {
        "titulo": "Manual Orders"
        , "texto": "Instantly accept card or debit payments via phone and email."
      },
      {
        "titulo": "Payment Button"
        , "texto": "Add a ‘click and pay’ button on an email, invoice or digital receipt or send a secure payment link via SMS."
      }
    ] 
  },
  "es":{
    "payTitulo": "Realiza todos tus pagos por teléfono o factura.",
    "payText": "Desde cualquier lugar, al instante y en cualquier dispositivo. Así es como tus clientes pueden realizar pagos por centralita y por factura.",    
    "payBtn": "CONTRATA AHORA",
    "payList": [
      {
        "titulo": "Pedidos Manuales"
        , "texto": "Acepta al instante pagos con tarjeta o débito vía teléfono y email."
      },
      {
        "titulo": "Botón de Pago"
        , "texto": "Añade un botón de ‘pulsa y paga’ en un email, factura o recibo digital o envía por SMS un vínculo de pago seguro."
      }
    ]  
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payUrl: "page_pay5",
      payListI: [
        {
          btnImg: require("@/assets/images/pay/icon_2colum_centralita_02.png")
        },
        {
          btnImg: require("@/assets/images/pay/icon_2colum_centralita_01.png")
        }
      ]
    };
  },
  computed: {
    payList() {
      return this.$t("payList");
    }
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  }
};
</script>